import { SET_USER } from 'store/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import auth from '../services/Auth';
import { checkPermissionForLocation } from './permissions';
import Loading from 'components/UI/Loading';

export const Private = ({ children }) => {
    const user = useSelector((state) => state.auth.user);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dataLoaded, setDataLoaded] = useState(false);

    const fetchData = async () => {
        const token = localStorage.getItem('tokencito');
        if (token) {
            try {
                const response = await auth.revalidateToken(token);
                if (response.status === 200) {
                    const data = response.data;
                    localStorage.setItem('tokencito', data.token);
                    dispatch({ type: SET_USER, payload: data });
                } else {
                    navigate('/iniciar-sesion');
                }
            } catch (error) {
                navigate('/iniciar-sesion');
            } finally {
                setDataLoaded(true);
            }
        } else {
            navigate('/iniciar-sesion');
            setDataLoaded(true);
        }
    };

    useEffect(() => {
        if (!user?.id) {
            fetchData();
        } else {
            const verification = checkPermissionForLocation(location.pathname, user.permissions);
            if (verification) {
                setDataLoaded(true);
            } else {
                navigate('/iniciar-sesion');
            }
        }
    }, [user]);

    useEffect(() => {
        if (user?.id && dataLoaded) {
            // Aquí puedes realizar la redirección automática según tus requisitos
            if (location.pathname === '/iniciar-sesion') {
                navigate('/dashboard/default');
            } else {
                navigate(location.pathname);
            }
        }
    }, [user, dataLoaded]);
    return dataLoaded ? <main>{children}</main> : <Loading />;
};
