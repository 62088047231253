import business from 'menu-items/business';
import MainRoutes from 'routes/MainRoutes';
import pages from 'menu-items/pages';

export function checkPermission(permission, userPermissions) {
    if (permission === true) {
        return true;
    }
    return userPermissions.includes(permission);
}

export function checkPermissionForLocation(pathname, userPermissions) {
    function validate(routes, type) {
        if (type === 'query') {
            const pathnameSegments = pathname.split('/').filter((segment) => segment !== '');

            for (const item of routes) {
                const itemSegments = item.path.split('/');

                if (pathnameSegments[0] === itemSegments[0]) {
                    return checkPermission(item.permission, userPermissions);
                }
            }
        } else {
            for (const item of routes.children) {
                if (pathname.startsWith(item.url)) {
                    return checkPermission(item.permission, userPermissions);
                }
            }
        }
    }

    if (pathname === '/' || pathname === '/iniciar-sesion') {
        return true;
    }

    if (validate(MainRoutes.children, 'query') || validate(business)) {
        return true;
    }
}
