import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import { useSelector } from 'react-redux';
import { checkPermission } from 'layout/permissions';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
    const theme = useTheme();
    const user = useSelector((state) => state.auth.user);

    // menu list collapse & items
    let items = null;
    if (user && user.permissions) {
        items = item.children?.map((menu) => {
            switch (menu.type) {
                case 'collapse':
                    return <NavCollapse key={menu.id} menu={menu} level={1} />;
                case 'item':
                    if (checkPermission(menu.permission, user.permissions)) {
                        return <NavItem key={menu.id} item={menu} level={1} />;
                    } else {
                        return null;
                    }
                default:
                    return (
                        <Typography key={menu.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });
    }

    let result; // Declaración de la variable fuera del bloque if

    if (user.id) {
        result = (() => {
            if (item.children[0].permission) {
                return item.children.some((itemcito) => checkPermission(itemcito.permission, user.permissions) === true);
                // return checkPermission(item.children[0].permission, user.permissions);
            } else if (item.children[0].children) {
                let result = item.children[0].children.some((itemcito) => checkPermission(itemcito.permission, user.permissions) === true);
                return result;
            }
        })();
    }

    return (
        <>
            {result ? (
                <>
                    <List
                        subheader={
                            item.title && (
                                <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
                                    {item.title}
                                    {item.caption && (
                                        <Typography
                                            variant="caption"
                                            sx={{ ...theme.typography.subMenuCaption }}
                                            display="block"
                                            gutterBottom
                                        >
                                            {item.caption}
                                        </Typography>
                                    )}
                                </Typography>
                            )
                        }
                    >
                        {items}
                    </List>
                    {/* group divider */}
                    <Divider sx={{ mt: 0.25, mb: 1.25 }} />
                </>
            ) : null}
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
